* {
	vertical-align: top;
}

html * {
	vertical-align: top;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	border: 0px none transparent;
	font-size: 100%;
	font-weight: inherit;
	font-variant: inherit;
	font-style: inherit;
	font-family: inherit;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}

a, img, label, span {
	display: inline-block;
}

a, a:visited, a:focus, a:active, a:hover, canvas, input, textarea {
	outline: 0px none transparent;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: "";
}

table {
	border-collapse: collapse;
	border-spacing: 0px;
}

br {
	display: block;
	height: 0px;
	line-height: 0px;
}

.fa, .fab, .fad, .fal, .far, .fas {
	vertical-align: baseline;
}
