:root {
  	--color-background: #ffffff; //#fafbfd

	--color-medium: #3A3A3D;
	--color-success: #7DC859;
	--color-danger: #DE6A3B;
	--color-brand: #3579db;

	--color-card: #f3f4f7;
	--color-border: #eff0f4;

	--color-text: #6b7684;
	--color-text-dark: #4b5767;
	--color-text-medium: #b4bbc4;
	--color-text-light: #dbdfe5;

	//--color-button: #525357;
	--color-link: #262f3d;

  	--color-input-background: #fafbfd;
	--color-input-border: #e5e5eb;
	--color-input-text: #A2A2A2;
}