// core things
@import "./reset";
@import "./variables";
@import "./fonts";
@import "./animations";
@import "./fix/fix-layout";
@import "./common";

// component-overrides, page-layout, etc.
@import "./components/nav-frames";

// Generic UI, or component things that are not inside component styles.
@import "./ui/buttons";
@import "./ui/inputs";
@import "./ui/typography";

// Modals
@import "./modals/modals";

// Global overrides - tramples over everything.
html, body {
	background: var(--color-background);
	color: var(--color-text);
	font-family: 'Poppins', 'Roboto', sans-serif;
	font-weight: 400;
}

// Primary app structure
#body-wrapper {
	padding: 30px;
}

#navigation {
	align-items: center;
	background: #ffffff;
	border-bottom: 2px solid var(--color-border);
	display: flex;
	grid-gap: 40px;
	//flex-direction: column;
	padding: 30px;

	@media only screen and (max-device-width: 1280px) {
		justify-content: space-between;
	}

	.logo {
		height: 40px;
	}

	ul {
		display: flex;
		grid-gap: 15px;
		//flex-direction: column;
		width: 100%;

		&.utility {
			grid-gap: 10px;
			//flex-direction: row-reverse;
			//margin-left: auto;
			width: auto;

			li {
				//aspect-ratio: 1;
				//background: var(--color-card);
				border-radius: 4px;
				color: var(--color-text);
				height: 40px;
				min-width: 40px;
				white-space: nowrap;

				&:hover, &.active {
					background: var(--color-card);
				}

				&.icon-text {
					padding-left: 15px;
				}

				.fa-solid, .fa-regular {
					color: var(--color-link);
					display: block;
					text-align: center;
					width: 40px;
				}
			}
		}

		li {
			align-items: center;
			color: var(--color-link);
			cursor: pointer;
			display: flex;
			grid-gap: 15px;
			font-size: 14px;
			font-weight: 500;
			justify-content: center;
			line-height: 40px;
			padding: 0 5px;
			position: relative;

			&:hover {
				color: var(--color-brand);
			}

			&.active {
				color: var(--color-brand);

				//&::after {
				//  border-bottom: 2px solid var(--color-brand);
				//  bottom: 0;
				//  content: '';
				//  left: 0;
				//  position: absolute;
				//  right: 0;
				//}
			}

			&.anchored {
				//margin-top: auto;
			}

			.fa-solid, .fa-regular {
				display: none;
				font-size: 20px;
				text-align: center;
				//width: 20px;
			}
		}
	}
}

#masthead {
	border-bottom: 2px solid var(--color-border);
	//display: flex;
	//justify-content: space-between;
	//margin-top: 80px;
	//padding: 20px;
	//margin: 10px 0 20px 0;
	margin-bottom: 30px;

	.header {
		display: flex;
		justify-content: space-between;
		padding: 20px 0;

		@media only screen and (max-device-width: 1280px) {
			//flex-direction: column;
			//grid-gap: 20px;
			padding: 0;
		}

		h1 {
			color: var(--color-text-dark);
			font-size: 26px;
			font-weight: 300;
			line-height: 40px;
			margin: 0;

			@media only screen and (max-device-width: 1280px) {
				//font-size: 20px;
				margin-bottom: 30px;
			}
		}

		.actions {
			display: flex;
			grid-gap: 15px;
		}

		span {
			font-size: 16px;
			line-height: 40px;
		}
	}

	.user {
		align-items: center;
		display: flex;
		grid-gap: 20px;

		.photo {
			aspect-ratio: 1;
			background: #949ba6;
			border-radius: 100%;
			color: #ffffff;
			font-size: 20px;
			font-weight: 100;
			height: 60px;
			line-height: 60px;
			text-align: center;
		}

		.details {
			span {
				display: block;
			}

			.name {
				font-weight: 600;
			}

			.id {
				font-size: 14px;
				font-weight: 300;
			}
		}
	}
}