.nomobile {
	@media only screen and (max-device-width: 1280px) {
		display: none !important;
	}
}

.mobileonly {
	display: none !important;

	@media only screen and (max-device-width: 1280px) {
		display: block !important;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	font-size: 13px;
	font-weight: 500;
	grid-gap: 25px;
	line-height: 40px;
	//padding-bottom: 10px;

	li {
		cursor: pointer;
		position: relative;

		@media only screen and (max-device-width: 1280px) {
			text-align: center;
			width: 100%;
		}

		&:hover {
			color: var(--color-link);
		}

		&.active {
			color: var(--color-link);

			&::after {
				border-bottom: 2px solid;
				bottom: -2px;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
			}
		}
	}
}

.data-table {
	border: 2px solid var(--color-border);
	border-radius: 3px;
	color: var(--color-text);
	font-size: 14px;
	//overflow: hidden;

	@media only screen and (max-device-width: 1280px) {
		border: none;
	}

	.entries {
		display: grid;

		@media only screen and (max-device-width: 1280px) {
			grid-gap: 20px;
		}

		.row {
			align-items: center;
			display: flex;
			grid-gap: 30px;
			justify-items: center;
			min-height: 40px;
			padding: 10px 20px;
			transition: color 0.3s ease-in-out;

			@media only screen and (max-device-width: 1280px) {
				border: 2px solid var(--color-border);
				border-bottom: 0;
				border-radius: 3px;
				grid-gap: 0;
				flex-direction: column;
				padding: 0;
			}

			&:hover {
				color: var(--color-text-dark);
			}

			&.header {
				//background: var(--color-border);
				background: var(--color-text) !important;
				border-radius: 3px 3px 0 0;
				//color: var(--color-text-dark);
				color: #ffffff;
				font-weight: 500;
				position: sticky;
				top: 0;

				@media only screen and (max-device-width: 1280px) {
					display: none;
				}

				.field {
					text-transform: uppercase;
				}
			}

			&:nth-child(odd) {
				background: var(--color-card);

				@media only screen and (max-device-width: 1280px) {
					background: none;
				}
			}

			.field {
				//padding: 10px;
				//min-width: 40px;
				overflow: hidden;
				text-overflow: ellipsis;
				text-transform: capitalize;
				white-space: nowrap;
				width: 100%;

				@media only screen and (max-device-width: 1280px) {
					align-items: center;
					border-bottom: 2px solid var(--color-border);
					display: flex;
					//grid-auto-flow: column;
					grid-gap: 15px;
					//justify-content: space-between;
					//padding-right: 20px;

					&:last-of-type {
						border: none;
					}

					&[data-label]::before {
						//background: var(--color-border);
						border-right: 2px solid var(--color-border);
						content: attr(data-label);
						display: block;
						font-weight: 500;
						padding: 15px 20px;
						width: 25%;
					}

					// Mobile-only card header. The odd logic for .actions is to boost the tap target size.
					&.header {
						background: var(--color-text) !important;
						border-radius: 3px 3px 0 0;
						box-sizing: border-box;
						color: #ffffff;
						height: 40px;
						line-height: 40px;
						padding-left: 15px;

						.actions {
							align-items: center;
							display: flex;
							float: right;
							height: 40px;
							padding: 0 20px;
						}
					}
				}

				&.centered {
					text-align: center;
				}

				&.select {
					color: var(--color-meta);
					cursor: pointer;
					font-size: 20px;
					overflow: visible;
					width: 40px;

					@media only screen and (max-device-width: 1280px) {
						display: none;
					}

					&:hover {
						color: unset;
					}
				}

				&.actions {
					//color: var(--color-meta);
					cursor: pointer;
					font-size: 20px;
					overflow: visible;
					width: 40px;

					@media only screen and (max-device-width: 1280px) {
						display: none;
					}
				}
			}
		}
	}
}