.button {
  align-items: center;
  background: transparent;
  border: 2px solid var(--color-border);
  border-radius: 3px;
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  grid-gap: 10px;
  justify-content: center;
  min-height: 40px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 0.8;
  }

  &.primary {
    background: var(--color-brand);
    border-color: var(--color-brand);
    color: #ffffff;
  }

  &.textonly {
    border-color: transparent;

    &:hover {
      color: var(--color-brand);
    }
  }
}